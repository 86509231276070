import gql from "graphql-tag";

export const orders = gql `
query orders($customerAccessToken: String!,$query:String!) {
  customer(customerAccessToken: $customerAccessToken) {
    orders(first: 10, query: $query) {
      edges {
        node {
          lineItems(first: 10) {
            edges {
              node {
                quantity
                title
                variant {
                  id
                  title
                  price
                  image {
                    url
                  }
                }
              }
            }
          }
          totalPrice
          totalShippingPrice
          fulfillmentStatus
          financialStatus
          id
        }
      }
    }
  }
}
`

export const cartCreate = gql `
mutation cartCreate($input: CartInput) {
  cartCreate(input: $input) {
    cart {
      id
    }
    userErrors {
      field
      message
    }
  }
}
`

export const cartLinesAdd = gql `
mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
  cartLinesAdd(cartId: $cartId, lines: $lines) {
    cart {
      id
    }
    userErrors {
      field
      message
    }
  }
}
`