import {
    query,
    mutate
} from "@/utils/apollo-utils.js"
import axios from '@/utils/request';

import {
    orders as ordersGql,
} from "./gqls.js"

export async function orders(payload) {
    return query(ordersGql, payload)
}

export async function draftOrders(payload) {
    return axios.post('shopify/query/draftOrders', payload, {
        'Content-Type': 'application/x-www-form-urlencoded'
    });
}

export async function queryOrders(payload) {
    return axios.post('shopify/query/orders', payload, {
        'Content-Type': 'application/x-www-form-urlencoded'
    });
}

export async function draftOrderDelete(payload) {
    return axios.post('shopify/draftOrderDelete', payload);
}

export async function orderCancel(payload) {
    return axios.post('shopify/orderCancel', payload);
}

export async function queryOrder() {
    return axios.get(`shopify/query/order`);
}

export async function b2bPreorder(payload) {
    return axios.post('cargo/pay/b2bPreorder', payload);
}
