<template>
  <div>
    <div style="background-color: white; padding: 15px">
      <span class="order-no"
        >{{ $t("Order No") }}.{{ order && order.name }}</span
      >
      <div class="order-status" v-if="isAli1688Vendor">
        {{ $t("This is a cross-border order") }}
      </div>
      <div class="order-status">{{ $t(status) }}</div>
    </div>

    <div v-if="order">
      <div v-for="line in order.lineItems.edges" :key="line.node.id">
        <div
          class="cart-item van-hairline--top-bottom"
          v-if="line.node.product"
        >
          <van-image
            width="80"
            height="80"
            @click.stop="toProduct(line.node.product.id)"
            :src="line.node.variant.image && line.node.variant.image.url"
          />
          <div class="cart-item-content">
            <div class="cart-item-bottom">
              <div class="product-title">
                {{
                  line.node.product.local_title
                    ? line.node.product.local_title.value
                    : line.node.product.title
                }}
              </div>
              <span>฿{{ line.node.variant.price }}</span>
            </div>
            <div class="cart-item-bottom" style="color: #999; padding: 5px 0px">
              <span style="flex: 1">
                {{ getSelectOptions(line.node) }}
              </span>
              <span> x{{ line.node.quantity }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="background-color: white; font-size: 0.9rem" v-if="order">
      <div class="van-hairline--bottom" style="padding: 5px 0px">
        <div
          class="freight-item"
          style="align-items: baseline"
          v-if="getDiscountAmount()"
        >
          <span class="freight-item-title">{{ $t("优惠") }}</span>
          <div style="text-align: right">
            <span style="display: block">฿{{ getDiscountAmount() }}</span>
            <span style="display: block; color: #999">{{
              getDiscountDescription()
            }}</span>
          </div>
        </div>
        <div class="freight-item">
          <span class="freight-item-title">{{ $t("Subtotal") }}</span>
          <span>฿{{ order && order.subtotalPrice }}</span>
        </div>
        <div class="freight-item">
          <span class="freight-item-title">{{ $t("Freight") }}</span>
          <span>{{ getFreightPrice() }}</span>
        </div>
        <div class="freight-item">
          <span class="freight-item-title" style="font-weight: bold">{{
            $t("Total")
          }}</span>
          <span>{{ getTotalPrice() }}</span>
        </div>
      </div>
    </div>

    <div class="order-refund" v-if="order && status == 'Cancelled'">
      <div class="freight-item van-hairline--bottom" style="padding: 10px 15px">
        <span class="freight-item-title">{{ $t("Refundable") }}</span>
        <span>฿{{ order.subtotalPrice }}</span>
      </div>
      <div class="freight-item" style="padding: 10px 15px">
        <span class="freight-item-title">{{ $t("Refund") }}</span>
        <span style="font-weight: bold">{{ $t("Refunded") }}</span>
      </div>
    </div>

    <div style="background-color: white" v-if="isCancelShow || isPayShow">
      <div style="text-align: right; padding: 10px 15px">
        <van-button
          v-if="isCancelShow"
          @click="cancelOrder"
          color="#EE502F"
          plain
          style="width: 70px; border-radius: 5px; margin: 0px 10px"
          :loading="submitLoading"
          :disabled="submitLoading"
          size="small"
        >
          {{ $t("Cancel") }}
        </van-button>

        <van-button
          v-if="isPayShow"
          @click="toPay"
          color="#EE502F"
          style="width: 70px; border-radius: 5px"
          :loading="submitLoading"
          :disabled="submitLoading"
          size="small"
        >
          {{ $t("Pay") }}
        </van-button>
      </div>
    </div>

    <div class="order-address" v-if="order && order.billingAddress">
      <div style="font-size: 1rem">
        <span>{{ order.billingAddress.firstName }}</span>
        <span style="display: inline-block; padding: 0px 15px">{{
          order.billingAddress.phone
        }}</span>
      </div>
      <div style="color: #555; margin-top: 5px">
        {{ $t("地址") }}:{{ order.billingAddress.address1 }}
        {{ order.billingAddress.address2 }} {{ order.billingAddress.zip }}
      </div>
    </div>

    <div
      class="order-refund"
      v-if="order && shopifyOrders"
      @click="toShippingDetail"
    >
      <div class="freight-item van-hairline--bottom" style="padding: 10px 15px">
        <div style="flex: 1">
          <span class="freight-item-title">{{
            $t("Additional Cross-border Shipping")
          }}</span>
          <div
            style="color: #999; font-size: 0.8rem"
            v-if="shopifyOrders && shopifyOrders.length !== 0"
          >
            {{
              $t("orders shipped together", { amount: shopifyOrders.length })
            }}
          </div>
        </div>
        <span>฿{{ freightOrder && freightOrder.totalForeignFee }}</span>
        <van-icon name="arrow" color="#555" />
      </div>
    </div>

    <div
      class="order-refund"
      v-if="routes && routes.length !== 0"
      style="padding: 10px 0px"
    >
      <div
        class="freight-item"
        style="align-items: baseline"
        v-for="route in routes"
        :key="route.description"
      >
        <span>{{ route.time }}</span>
        <span style="flex: 1; display: inline-block; padding-left: 10px">{{
          route.description
        }}</span>
      </div>
    </div>

    <div class="order-refund" v-if="waybills && waybills.length !== 0">
      <div style="font-weight: bold; padding: 10px 15px; font-size: 1.1rem">
        {{ $t("Tracking") }}
      </div>
      <div
        class="freight-item van-hairline--bottom"
        style="padding: 10px 15px"
        v-for="bill in waybills"
        :key="bill.id"
        @click="toTracking(bill.id)"
      >
        <span class="freight-item-title"
          >{{ bill.fTrackingNo }} ({{ bill.fExpressCompany }})</span
        >
        <van-icon name="arrow" color="#555" />
      </div>
    </div>

    <van-dialog
      v-model="cancelDialogShow"
      title=""
      show-cancel-button
      :cancelButtonText="$t('取消')"
      :confirmButtonText="$t('确定')"
      @confirm="onCancelConfirm"
    >
      <div style="padding: 20px 15px; text-align: center">
        <div>
          <div v-if="toReceive">
            {{
              $t(
                "This order has been delivered to our warehouse. The logistic fee for send it back to the supplier will be deducted from the refund. Are you sure you want to cancel?"
              )
            }}
          </div>
          <div v-if="!toReceive">
            {{
              $t("Are you sure you want to cancel order", {
                orderName: order && order.name,
              })
            }}
          </div>
          <div class="refund-policy">{{ $t("Refund policy>>") }}</div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {
  Toast,
  Cell,
  CellGroup,
  Button,
  Step,
  Steps,
  Image as VanImage,
  Dialog,
  Icon,
} from "vant";
import { queryDraftOrder, queryOrder, packages, routes } from "./service";
import { draftOrderDelete, orderCancel } from "../service";
import { shippingDetails } from "./service";

import {
  ORDER_TYPE,
  VENDOR,
  SHOPIFY_TAG,
  PAYMENTITEM,
} from "@/utils/constants";
import qs from "qs";
import {
  formatTime,
  transStringToTimeStamp,
  formatThValue,
  formatSkuValue,
} from "@/utils/utils";
import { getLocale } from "@/utils/storage";

export default {
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [VanImage.name]: VanImage,
    [Icon.name]: Icon,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      submitLoading: false,
      status: "",
      id: this.$route.query.id,
      order: null,
      isAli1688Vendor: false,
      isCancelShow: false,
      isPayShow: false,
      routes: [],
      cancelDialogShow: false,
      toReceive: false,
      waybills: null,
      freightOrder: null,
      shopifyOrders: null,
      freightOrderExtra: null,
    };
  },
  methods: {
    getDiscountAmount() {
      const discount = this.getDiscount();
      return discount ? discount.value : discount;
    },
    getDiscountDescription() {
      const discount = this.getDiscount();
      return discount ? discount.description : discount;
    },
    getDiscount() {
      if (this.status == ORDER_TYPE.toBeConfirmed) {
        const { appliedDiscount } = this.order;
        if (appliedDiscount) {
          const { description, value } = appliedDiscount;
          return { description, value };
        }
      } else {
        const { discountApplications } = this.order;
        if (discountApplications && discountApplications.edges.length > 0) {
          let discount;
          discountApplications.edges.forEach(({ node }) => {
            const { allocationMethod, description, value } = node;
            if (allocationMethod == "ACROSS") {
              discount = { description, value: value.amount };
              return;
            }
          });
          return discount;
        }
      }
    },
    toTracking(id) {
      this.$router.push({
        name: "tracking",
        query: {
          id,
        },
      });
    },
    toShippingDetail() {
      this.$router.push({
        name: "shippingDetail",
        query: { shopifyOrderId: this.id },
      });
    },
    getFreightPrice() {
      if (this.status == ORDER_TYPE.toBeConfirmed)
        return this.calculateingPrice(this.order.totalShippingPrice);
      return "฿" + this.order.totalShippingPrice;
    },
    getTotalPrice() {
      return "฿" + this.order.totalPrice;
    },
    getRouteDescription(route) {
      const {
        title,
        description,
        enTitle,
        enDescription,
        localTitle,
        localDescription,
      } = route;

      const locale = getLocale();
      switch (locale) {
        case "zh-TW":
          return description;
        case "th-TH":
          return localDescription;
        default:
          return enDescription;
      }
    },
    getRouteCreateTime(route) {
      const { createdAt } = route;
      return formatTime(createdAt);
    },
    toPay() {
      if (this.status == ORDER_TYPE.toPay) {
        const { id, name, totalPrice } = this.order;
        const payload = {
          orderIds: id,
          orderNames: name,
          total: totalPrice,
          paymentItem: PAYMENTITEM.b2b_order,
        };
        this.$router.push({
          name: "pay",
          query: { ...payload },
        });
      }
    },
    cancelShow() {
      switch (this.status) {
        case ORDER_TYPE.toBeConfirmed:
        case ORDER_TYPE.toPay:
          return true;
        case ORDER_TYPE.toReceive:
        case ORDER_TYPE.cancelled:
        case ORDER_TYPE.completed:
          return false;
        case ORDER_TYPE.toShip:
          return !this.isOrderPacked();
      }
    },
    cancelOrder() {
      this.cancelDialogShow = true;
    },
    onCancelConfirm() {
      if (!this.order) return;
      switch (this.status) {
        case ORDER_TYPE.toBeConfirmed:
          this.draftOrderDelete();
          break;
        case ORDER_TYPE.toPay:
        case ORDER_TYPE.toShip:
          this.orderCancel();
          break;
      }
    },
    async orderCancel() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });

      const { order } = await orderCancel({
        orderId: this.order.id,
      });
      Toast.clear();
      if (order) {
        setTimeout(() => {
          Toast.success(this.$t("订单取消成功"));
          this.$router.go(-1);
        }, 500);
      }
    },
    async draftOrderDelete() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });

      const { data } = await draftOrderDelete({
        draftOrderId: this.order.id,
      });
      Toast.clear();
      if (data) {
        const { draftOrderDelete } = data;
        const { userErrors } = draftOrderDelete;
        if (userErrors && userErrors.length > 0) {
          const { message } = userErrors[0];
          Toast.fail(message);
        } else {
          setTimeout(() => {
            Toast.success(this.$t("订单取消成功"));
            this.$router.go(-1);
          }, 500);
        }
      }
    },
    payShow() {
      switch (this.status) {
        case ORDER_TYPE.toBeConfirmed:
        case ORDER_TYPE.toReceive:
        case ORDER_TYPE.cancelled:
        case ORDER_TYPE.toShip:
        case ORDER_TYPE.completed:
          return false;
        case ORDER_TYPE.toPay: {
          const { landFreightTotal, seaFreightTotal } =
            this.calculateEstimateFreight();
          return landFreightTotal || seaFreightTotal;
        }
      }
    },
    calculateEstimateFreight() {
      let landFreightTotal = 0;
      let seaFreightTotal = 0;

      const { estimation_first_leg_freight, estimation_second_leg_freight } =
        this.order;
      if (estimation_first_leg_freight) {
        const firstLegFreight = JSON.parse(estimation_first_leg_freight.value);

        if (firstLegFreight && firstLegFreight.length === 2) {
          const landFreight = firstLegFreight[0];
          const seadFreight = firstLegFreight[1];

          landFreightTotal = this.NumberAdd(landFreightTotal, landFreight);
          seaFreightTotal = this.NumberAdd(seaFreightTotal, seadFreight);
        }
      }
      if (estimation_second_leg_freight) {
        landFreightTotal = this.NumberAdd(
          landFreightTotal,
          estimation_second_leg_freight.value
        );
        seaFreightTotal = this.NumberAdd(
          seaFreightTotal,
          estimation_second_leg_freight.value
        );
      }
      return { landFreightTotal, seaFreightTotal };
    },
    isOrderPacked() {
      const { packed_time } = this.order;
      return packed_time && packed_time.value;
    },
    ali1688Vendor() {
      let isAli1688Vendor = false;
      if (this.order && this.order.tags) {
        const { tags } = this.order;
        isAli1688Vendor =
          tags.includes(SHOPIFY_TAG.cross_border) ||
          tags.includes(SHOPIFY_TAG.cross_border_to_ship) ||
          tags.includes(SHOPIFY_TAG.cross_border_to_receive);
      }
      this.isAli1688Vendor = isAli1688Vendor;
    },
    calculateingPrice(price) {
      return price && price != 0 ? `฿${price}` : this.$t("Calculating");
    },
    getSelectOptions(node) {
      const { attribute, variant } = node;
      const { id, selectedOptions } = variant;

      let sku;
      if (id) {
        const options = selectedOptions.map((opt) => {
          const { name, value } = opt;
          return `${this.formatThValue(name)}:${this.formatThValue(value)}`;
        });

        sku = options.join(" ");
      } else {
        sku = formatSkuValue(attribute["sku"]);
      }
      return sku;
    },
    formatThValue(value) {
      return formatThValue(value);
    },
    toProduct(id) {
      this.$router.push({
        name: "Product",
        query: { id },
      });
    },
    async draftOrder() {
      const payload = {
        query: `id:\\"${this.id}\\"`,
        returnsQlStr: `{
                        id
                        createdAt
                        name
                        tags
                        status
                        totalPrice
                        subtotalPrice
                        totalShippingPrice   
                        appliedDiscount{
                          description
                          value
                        } 
                        lineItems(first:100){
                          edges {
                            node {
                              id
                              quantity
                              title
                              product {
                                id
                                vendor
                                title
                                local_title:metafield(namespace:\\"custom\\",key:\\"local_title\\"){
                                  value
                                }
                              }
                              originalUnitPrice
                              customAttributes {
                                key
                                value
                              }
                              variant {
                                id
                                title
                                price
                                image {
                                  url
                                }
                                selectedOptions {
                                  name
                                  value
                                }
                              }
                            }
                          }
                        }
                        billingAddress {
                          address2
                          address1
                          firstName
                          lastName
                          zip
                          phone
                        }
                      }`,
      };

      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });
      const { data } = await queryDraftOrder(qs.stringify(payload));
      Toast.clear();
      const { draftOrder } = data;
      if (draftOrder) {
        const { lineItems } = draftOrder;
        lineItems.edges.forEach((item) => {
          const { customAttributes, variant, originalUnitPrice, title } =
            item.node;
          if (!variant) {
            let attribute = {};
            customAttributes.forEach((attr) => {
              const { key, value } = attr;
              attribute[key] = value;
            });
            item.node.attribute = attribute;
            item.node.product = {
              id: attribute.productId,
              title,
              vendor: attribute.vendor,
            };
            item.node.variant = {
              image: { url: attribute.image },
              price: originalUnitPrice,
            };
          }
        });

        this.order = draftOrder;
        this.ali1688Vendor();
        this.isCancelShow = this.cancelShow();
        this.isPayShow = this.payShow();
      }
    },
    generateRoutes() {
      let routes = [];

      const { createdAt: orderCreateAt, fulfillments } = this.order;
      const createRoute = {
        time: formatTime(orderCreateAt),
        description: this.$t("Order placed"),
      };
      routes.push(createRoute);

      if (fulfillments && fulfillments.length !== 0) {
        let fulfillmentRoutes = fulfillments.map((f) => {
          const { createdAt, trackingInfo } = f;
          let trackingNo;
          if (trackingInfo && trackingInfo.length !== 0) {
            const express = trackingInfo[0];
            const { company, number } = express;
            trackingNo = number;
          }

          return {
            time: formatTime(createdAt),
            description: `${this.$t(
              "Fulfilled by the supplier"
            )} {${trackingNo}}`,
          };
        });
        fulfillmentRoutes = fulfillmentRoutes.sort((a, b) => {
          const atime = transStringToTimeStamp(a.time);
          const btime = transStringToTimeStamp(b.time);
          return atime - btime;
        });

        routes = [...routes, ...fulfillmentRoutes];
      }

      const { ready_to_transit_time, freight_pay_time, packed_time } =
        this.order;

      if (ready_to_transit_time) {
        const readyToTransitRoute = {
          time: formatTime(ready_to_transit_time.value),
          description: `${this.$t("Arrived at the transite warehouse")}`,
        };
        routes.push(readyToTransitRoute);
      }

      if (freight_pay_time) {
        const freightPayRoute = {
          time: formatTime(freight_pay_time.value),
          description: this.$t(`Cross border Freight Paid`),
        };
        routes.push(freightPayRoute);
      }

      if (packed_time) {
        const packedRoute = {
          time: formatTime(packed_time.value),
          description: this.$t(`Ready to ship to Thailand`),
        };
        routes.push(packedRoute);
      }

      this.routes = routes;
    },
    orderStatus() {
      if (this.order.cancelledAt) {
        this.status = ORDER_TYPE.cancelled;
        return;
      }

      if (this.order.closed) {
        this.status = ORDER_TYPE.completed;
        return;
      }

      if (!this.order.fullyPaid) {
        this.status = ORDER_TYPE.toPay;
      } else {
        const { tags } = this.order;
        if (tags.includes(SHOPIFY_TAG.local)) {
          this.status = ORDER_TYPE.toShip;
        }

        if (tags.includes(SHOPIFY_TAG.cross_border_to_ship)) {
          this.status = ORDER_TYPE.toShip;
        }

        if (tags.includes(SHOPIFY_TAG.cross_border_to_receive)) {
          this.status = ORDER_TYPE.toReceive;
        }
      }
    },
    async queryOrder() {
      const payload = {
        query: `id:\\"${this.id}\\"`,
        returnsQlStr: `{
                        id
                        createdAt
                        cancelledAt
                        closed
                        name
                        tags
                        totalPrice
                        subtotalPrice
                        totalShippingPrice    
                        discountApplications(first: 100) {
                          edges {
                            node {
                              ... on ManualDiscountApplication {
                                description
                                value {
                                  ... on MoneyV2 {
                                    __typename
                                    amount
                                  }
                                }
                                allocationMethod
                              }
                            }
                          }
                        }
                        fullyPaid
                        fulfillable
                        lineItems(first:100){
                          edges {
                            node {
                              id
                              quantity
                              title
                              product {
                                id
                                vendor
                              }
                              originalUnitPrice
                              customAttributes {
                                key
                                value
                              }
                              variant {
                                id
                                title
                                price
                                image {
                                  url
                                }
                                selectedOptions {
                                  name
                                  value
                                }
                              }
                            }
                          }
                        }
                        estimation_first_leg_freight:metafield(namespace:\\"custom\\",key:\\"estimation_first_leg_freight\\"){
                          value
                        }
                        estimation_second_leg_freight:metafield(namespace:\\"custom\\",key:\\"estimation_second_leg_freight\\"){
                          value
                        }
                        ready_to_transit_time:metafield(namespace:\\"custom\\",key:\\"ready_to_transit_time\\"){
                          value
                        }
                        packed_time:metafield(namespace:\\"custom\\",key:\\"packed_time\\"){
                          value
                        }
                        freight_pay_time:metafield(namespace:\\"custom\\",key:\\"freight_pay_time\\"){
                          value
                        }
                        fulfillments {
                          createdAt
                          trackingInfo(first: 50) {
                            number
                            company
                          }
                        }
                        billingAddress {
                          address2
                          address1
                          firstName
                          lastName
                          zip
                          phone
                        }
                      }`,
      };

      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });
      const { data } = await queryOrder(qs.stringify(payload));

      Toast.clear();
      const { order } = data;
      if (order) {
        const { tags } = order;

        if (tags && tags.length !== 0) {
          this.toReceive = tags.includes(SHOPIFY_TAG.cross_border_to_ship);
        }

        const { lineItems } = order;
        lineItems.edges.forEach((item) => {
          const { customAttributes, variant, originalUnitPrice, title } =
            item.node;
          if (!variant) {
            let attribute = {};
            customAttributes.forEach((attr) => {
              const { key, value } = attr;
              attribute[key] = value;
            });
            item.node.attribute = attribute;
            item.node.product = {
              id: attribute.productId,
              title,
              vendor: attribute.vendor,
            };
            item.node.variant = {
              image: { url: attribute.image },
              price: originalUnitPrice,
            };
          }
        });

        this.order = order;

        this.orderStatus();
        this.ali1688Vendor();
        this.isCancelShow = this.cancelShow();
        this.isPayShow = this.payShow();
        this.generateRoutes();

        if (
          this.status == ORDER_TYPE.toReceive ||
          this.status == ORDER_TYPE.completed
        )
          this.shippingDetails();
      }
    },
    async shippingDetails() {
      const { success, data, message } = await shippingDetails(this.id);
      if (success) {
        const { waybills, freightOrder, shopifyOrders, freightOrderExtra } =
          data;

        this.waybills = waybills;
        this.freightOrder = freightOrder;
        this.shopifyOrders = shopifyOrders;
        this.freightOrderExtra = freightOrderExtra;
      } else Toast.fail(message);
    },
    initialize() {
      if (this.id.includes("DraftOrder")) {
        this.status = ORDER_TYPE.toBeConfirmed;
        this.draftOrder();
      } else this.queryOrder();
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style lang="less" scoped>
.order-no {
  font-size: 1.2rem;
}

.order-status {
  color: #555;
  font-size: 0.9rem;
}

.freight-content {
  background-color: white;
  margin-top: 10px;
  font-size: 0.9rem;
}

.freight-item {
  display: flex;
  align-items: center;
  padding: 5px 15px;
}

.freight-item-title {
  flex: 1;
}

.cart-item {
  display: flex;
  padding: 15px;
  font-size: 0.9rem;
  background-color: white;
}

.cart-item-content {
  flex: 1;
  padding-left: 10px;
}

.cart-item-price {
  display: inline-block;
  padding: 0px 5px;
  font-size: 1.5rem;
}

.cart-item-bottom {
  display: flex;
  align-items: baseline;
}

.sku {
  color: #999;
  font-size: 0.8rem;
  padding: 5px 0px;
}

.product-title {
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  text-align: start;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.quantity {
  flex: 1;
  text-align: end;
  color: #333;
  font-size: large;
}

.refund-policy {
  color: #3399ff;
}

.order-address {
  background-color: white;
  padding: 15px;
  margin: 10px 0px;
  font-size: 0.9rem;
}

.order-refund {
  background-color: white;
  margin-top: 10px;
  font-size: 0.9rem;
}
</style>