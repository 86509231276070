import axios from '@/utils/request';

export async function queryDraftOrder(payload) {
    return axios.post('shopify/query/draftOrder', payload, {
        'Content-Type': 'application/x-www-form-urlencoded'
    });
}

export async function queryOrder(payload) {
    return axios.post('shopify/query/order', payload, {
        'Content-Type': 'application/x-www-form-urlencoded'
    });
}

export async function packages(orderNo) {
    return axios.get(`shopify/packages/${orderNo}`);
}

export async function routes(orderNo, cargoId) {
    return axios.get(`shopify/routes/${orderNo}/${cargoId}`);
}

export async function shippingDetails(shopifyOrderId) {
    return axios.get(`/shopify/shipping/details`, {
        shopifyOrderId
    });
}